.fields-selector{
	.fields-selector-item:hover {
		background-color: var(--bg-c3);
		cursor: pointer;
	}
}
.line-items-table{
	background-color: transparent;
	border-collapse: collapse;
	border-spacing: 0.15em;
}
.line-items-table tr td {
	padding: 0.15em;
}

.line-item-input::placeholder {
	opacity: 0.5;
}
.line-item-input{
	font-family: var(--font-primary);
	color: var(--fg-c1);
	height: 2.25em;
	padding: 8px 8px 8px 8px;
	border-radius: 0.5em;
	font-size: 1em;
	background-color: transparent;
	border: 0;
	background: transparent;
	box-shadow: 0 0;
	transition: border-color 0.3s, box-shadow 0.3s, outline 0.3s;
	margin: 0.15em;
	outline: 0.1em solid var(--border-1);
}

.line-item-input:disabled{
	font-family: var(--font-primary);
	color: var(--fg-c1);
	height: 2.25em;
	padding: 0.5em;
	border-radius: 0.5em;
	font-size: 1em;
	background-color: transparent;
	border: 0;
	background: transparent;
	box-shadow: 0 0;
	transition: border-color 0.3s, box-shadow 0.3s, outline 0.3s;
	margin: 0.15em;
	outline: 0;
}

.line-item-input:hover{
	box-shadow: inset 0px 0px 0.5em 0.1em rgba(0, 0, 0, 0.1);
	outline: 0.1em solid var(--fg-primary);
	transition: box-shadow 0.6s, outline 0.6s;
}
.line-item-input:disabled:hover{
	box-shadow: none;
	outline: 0
}
.line-item-input:focus{
	box-shadow: inset 0px 0px 0.5em 0.1em rgba(0, 0, 0, 0.2);
	outline: 0.1em solid var(--fg-primary);
	transition: box-shadow 0.3s, outline 0.3s;
}

.line-item-button{
	margin: 0.1em;
	border-radius: 1em;
}
